<template>

  <div class="card card-custom card-stretch gutter-b">

    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark pl-2">{{ $t('PAGES.MEMBER_PROPERTY.HEADER_GROUP_ATTRIBUTES') }}</span>
      </h3>
      <div class="card-toolbar">

        <a
          href="#"
          class="btn btn-primary font-weight-bolder font-size-sm"
          @click.prevent="createGroupAttributeClicked"
          ><i class="menu-icon flaticon2-plus" style="font-size: 1em"></i>{{ $t('PAGES.MEMBER_PROPERTY.NEW_ATTRIBUTE') }}</a
        >
      </div>
    </div>

    <div class="card-body pt-0 table-responsive">
      <b-table
        id="stickprov-table"
        :fields="headers"
        :items="properties"
        head-variant="light"
        class='table-striped w-100 mh-100'
        sticky-header
      >
        <template #cell(action)='row'>
          <div class='d-flex justify-content-end'>
            <a
              href="#"
              class="btn btn-icon btn-light btn-sm mx-3"
              @click.prevent="selectGroupAttributeClicked(row.item.prop_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>
            <a
              href="#"
              class="btn btn-icon btn-light btn-sm"
              @click.prevent="deleteGroupAttributeClicked(row.item.prop_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>
          </div>
        </template>
      </b-table>
    </div>

    <div class="card-body pt-0 pb-3">

      <div class="scroll-to-attributes"></div>
      <div v-if="creating || editing">
        <div class="card card-custom card-stretch gutter-b">
          <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">{{ $t('ATTRIBUTE.EDIT_ATTRIBUTE') }}</span>
            </h3>
          </div>
          <div class="card-body pt-0 pb-3">
            <b-form class="mt-8">
              <b-form-group id="input-group-prop_id" label="ID" label-for="input-prop_id">
                <b-form-input
                  id="input-prop_id"
                  v-model="form.prop_id"
                  type="text"
                  disabled
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-prop-name"
                :label="$t('ATTRIBUTE.NAME')"
                label-for="input-prop-name">
                <b-form-input
                  id="input-prop-name"
                  v-model="form.name"
                  type="text">
                </b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-prop-descr"
                :label="$t('ATTRIBUTE.DESCR')"
                label-for="input-prop-descr"
              >
                <b-form-textarea
                  id="input-prop-descr"
                  size="sm"
                  :placeholder="$t('ATTRIBUTE.DESCR_PLACEHOLDER')"
                  v-model="form.descr"
                ></b-form-textarea>
              </b-form-group>

              <div class="d-flex align-items-center">
                <label class="checkbox checkbox-lg checkbox-outline checkbox-success">
                  <input
                    type="checkbox"
                    name=""
                    v-model="form.is_free_text"
                    @click="form.is_free_text = !form.is_free_text"
                  />
                  <span></span>
                </label>
                <span class="ml-3 cursor-pointer">{{$t('ATTRIBUTE.IS_FREE_TEXT')}}</span>
              </div>

              <RightSaveButton
                ref="save-button"
                :disabled="disabled_button"
                :text="$t('ATTRIBUTE.SAVE')"
                @clicked="save_data()"
              />

            </b-form>
          </div>
        </div>
      </div>

    </div>

  </div>

</template>

<style lang="scss" scoped>

</style>


<script>
import axios from 'axios';
import { validationMixin } from 'vuelidate';
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import RightSaveButton from '@/view/components/buttons/RightSaveButton.vue';

export default {
  mixins: [validationMixin, toasts],
  name: 'member-property-table',

  computed: {
    disabled_button() {
      return this.form.name === '';
    },

    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies'])
  },
  props: ['group_id'],
  emits: ['deleteGroupAttributeClicked'],
  components: {
    RightSaveButton
  },
  mounted() {

    this.load_attributes();
  },
  watch: {
    group_id(newValue, oldvalue) {
      this.creating = false;
      this.editing = false;
      this.load_attributes();
    }
  },
  methods: {
    async save_data() {
      await this.update_property();

      this.$refs['save-button'].stop();

      this.$nextTick(()=>{
        this.creating = false;
        this.editing = false;
      });
    },
    async createGroupAttributeClicked() {


      try {
        this.creating = false;
        this.editing = true;

        this.scrollToEditGroup();

        this.form.company_id = this.currentCompanyId;
        this.form.period_id = this.currentPeriodId;
        this.form.group_id = this.group_id;
        this.form.name = '';
        this.form.descr = '';

        const res = await axios.post('/member_property', this.form)

        if (res.status === 201) {
          this.creating = false;
          this.form = res.data;

          this.toastr('success', this.$t('COMMON.OK'), this.$t('ATTRIBUTE.CREATED'));
          await this.load_attributes();
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ATTRIBUTE.UNABLE_CREATE'));
        }
      }
      catch (err) {
        console.error('createGroupAttributeClicked', err);
      }

    },
    async load_attributes() {
      try {
        if (!this.group_id) {
          return;
        }

        this.properties = [];

        const res = await axios.get(`/member_property?group_id=${this.group_id}`)
        if (res.status === 200) {
          this.properties = res.data;
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ATTRIBUTE.UNABLE_LIST'));
        }
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ATTRIBUTE.UNABLE_LIST'));
      }
    },
    selectGroupAttributeClicked(prop_id) {
      this.editing = true;
      this.creating = false;
      this.scrollToEditGroup();
      for (let i = 0;i < this.properties.length; i++)
        if (prop_id == this.properties[i].prop_id) {
          this.form = this.properties[i];
          break;
        }
    },
    async update_property() {
      try {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ATTRIBUT.FORM_INVALID'));
          return;
        }

        const res = await axios.put(`/member_property/${this.form.prop_id}`, this.form)

        if (res.status === 200) {
          this.form = res.data;
          this.toastr('success', this.$t('COMMON.OK'), this.$t('ATTRIBUTE.UPDATED'));
          await this.load_attributes();
        }
        else {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ATTRIBUTE.UNABLE_UPDATE'));
        }
      }
      catch (err) {
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ATTRIBUTE.UNABLE_UPDATE'));
      }

    },
    deleteGroupAttributeClicked(prop_id) {
      axios
        .delete(`/member_property/${prop_id}`)
        .then(res => {
          this.properties = this.properties.filter(item => item.prop_id !== prop_id);

          this.creating = false;
          this.editing = false;

          this.toastr('success', this.$t('COMMON.OK'), this.$t('ATTRIBUTE.DELETED'));
        })
        .catch(err => {
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('ATTRIBUTE.UNABLE_DELETE'));
        });

      this.$emit('deleteGroupAttributeClicked', prop_id);
    },
    scrollToEditGroup() {
      var that = this;
      setTimeout(function () {
        const el = that.$el.getElementsByClassName('scroll-to-attributes')[0];

        if (el) {
          const yOffset = -70;
          const y = el.getBoundingClientRect().top + window.pageYOffset + yOffset;

          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 100);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
  },
  data() {
    return {
      properties: [],
      headers: [
        {
          key: 'name',
          label: this.$t('MEMBER.ATTRIBUTE'),
          sortable: true,
          thClass: 'pl-7 w-100',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'action',
          label: '',
          thClass: 'w-110px',
          tdClass: 'align-middle'
        },
      ],
      list: [],
      form: {
        prop_id: '',
        name: '',
        descr: '',
        company_id: null,
        period_id: null
      },
      creating: false,
      editing: false
    };
  },
  validations: {
    form: {
      name: { required, minLength: minLength(1) }
    }
  },
};
</script>
